function initTitleResize() {
    function updateFontSizes() {
        const headers = document.querySelectorAll('[data-shrink-header]');
        const stickyHeader = document.querySelector('[data-sticky-header]');
        
        if (!headers.length || !stickyHeader) {
            return;
        }

        const stickyHeaderHeight = stickyHeader.offsetHeight;
        const scrollY = window.scrollY;

        headers.forEach(header => {
            const initialFontSize = parseFloat(header.dataset.initialFontSize);
            const minFontSize = parseFloat(header.dataset.minFontSize);
            const maxScroll = parseFloat(header.dataset.maxScroll) || (stickyHeaderHeight - 70);

            const newFontSize = Math.max(minFontSize, initialFontSize - (scrollY / maxScroll) * (initialFontSize - minFontSize));
            header.style.fontSize = newFontSize + 'rem';
        });
    }

    // Call updateFontSizes immediately to set the correct initial size
    updateFontSizes();

    // Add event listeners after DOM is loaded
    document.addEventListener('DOMContentLoaded', function () {
        // Ensure we're at the top of the page
        window.scrollTo(0, 0);
        
        document.addEventListener('scroll', updateFontSizes);
        // Also update on resize in case the layout changes
        window.addEventListener('resize', updateFontSizes);
    });
}
export { initTitleResize };