// All Functions for Latest 

function initLatest() {
  checkPriceZero();
  checkSummaryPage();
  breakProductSpecification();
}

// Check if the price is 0 and replace it with POA
function checkPriceZero() {
    const prices = document.querySelectorAll('.collection-item-variant-prices');
    prices.forEach((price) => {
        if (price.textContent.includes('$0')) {

            price.textContent = 'POA';
        }
    });

    //After SPRIG request completes
    document.body.addEventListener('htmx:afterSwap', function(event) {
        checkPriceZero();
    });
}

function checkSummaryPage() {
    const summaryPage = document.querySelector('.selection-summary');
    const sidebar = document.querySelector('#side-quote-panel');
    if (summaryPage && sidebar) {
        console.log('Summary page detected');
        sidebar.classList.add('hidden');
    }
}

function breakProductSpecification() {
    const accordionSpecs = document.querySelector('#accordion-specs');
    if (accordionSpecs) {
        const accordionSpecItems = accordionSpecs.querySelectorAll('.collection-item-description');
        accordionSpecItems.forEach(item => {
            const text = item.textContent.trim();
            if (text.includes(',')) {
                const parts = text.split(',');
                item.innerHTML = parts.join('<br>');
            }
        });
    }
}

export { initLatest };